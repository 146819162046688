import React, { useEffect, useState } from 'react';
import { Button, Table, Modal, Space } from 'antd';
import 'antd/dist/reset.css'; // Ensure you import 'antd.css' for the full styles
import { MessageFilled } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { fetchProviders, setSMSCredit, fetchStripeOrders } from '../features/providers/providersSlice';
import { useProviderContext } from '../components/ProviderContext';
import moment from 'moment';

interface OrderHistoryRecord {
  key: string;
  date: string;
  frequency: string;
  added: number;
  remaining: number;
}

const columns = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Frequency',
    dataIndex: 'frequency',
    key: 'frequency',
  },
  {
    title: 'Added',
    dataIndex: 'added',
    key: 'added',
  },
  {
    title: 'Remaining Days',
    dataIndex: 'remaining',
    key: 'remaining',
    render: (remaining: number) => {
      const color = remaining < 1 ? 'red' : 'green';
      const dayText = remaining === 1 ? 'day' : 'days';
      return (
        <span style={{ color }}>
          {remaining} {dayText}
        </span>
      );
    },
  },
];

const FormSMS: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [orderHistoryData, setOrderHistoryData] = useState<OrderHistoryRecord[]>([]);
  
  const dispatch = useDispatch<AppDispatch>();
  const providersStatus = useSelector((state: RootState) => state.providers.status);
  const { selectedProviderId } = useProviderContext();

  const providers = useSelector((state: RootState) =>
    state.providers.data.find(prov => prov.centre_id === selectedProviderId)
  );

  const stripeOrders = useSelector((state: RootState) => state.providers.stripeOrders);
  const [SMSCount, setSMSCount] = useState(providers?.Subscription?.smsCountLeft || 0);

  useEffect(() => {
    if (providersStatus === 'idle') {
      dispatch(fetchProviders());
    }
  }, [dispatch, providersStatus]);

  useEffect(() => {
    if (providers?.Subscription.stripeCustomerId) {
      dispatch(fetchStripeOrders());
    }
  }, [dispatch, providers?.Subscription.stripeCustomerId]);

  useEffect(() => {
    if (providers) {
      setSMSCount(providers?.Subscription?.smsCountLeft || 0);
    }
  }, [providers]);

  useEffect(() => {
    if (stripeOrders) {
      const smsData = stripeOrders.SMS.map((record, index) => ({
        key: index.toString(),
        date: moment(record.date).format('DD.MM.YYYY'),
        frequency: record.frequency === 'month' ? 'Monthly' : 'Yearly',
        added: record.added,
        remaining: record.remainingDays,
      }));
      setOrderHistoryData(smsData);
    }
  }, [stripeOrders]);

  const showModal = () => setIsModalVisible(true);
  const closeModal = () => setIsModalVisible(false);

  const handleAddSMSCredit = async () => {
    await dispatch(setSMSCredit({ subscriptionId: providers?.Subscription.subscription_id, amount: SMSCount }));
    dispatch(fetchProviders());
  };

  const addSMS = (value: number) => {
    setSMSCount(SMSCount + value);
  };

  const removeSMS = (value: number) => {
    if (SMSCount > 0 && SMSCount - value > 0) {
      setSMSCount(SMSCount - value);
    }
  };

  // Styles
  const borderStyle = {
    borderRadius: "8px",
    border: "1px solid rgba(0, 0, 0, 0.06)",
    maxWidth: '1386px', maxHeight: '725px'
  };

  const borderStyle_Card = {
    borderRadius: "8px",
    border: "1px solid rgba(0, 0, 0, 0.06)",
    maxWidth: '430px', height: '141px',
  };

  const subscriptionHistoryLayout = {
    display: 'flex',
    gap: '64px',
    alignSelf: 'stretch',
  };

  const headingStyle = {
    color: "var(--colorTextHeading, rgba(0, 0, 0, 0.88))",
    fontFamily: 'SF Pro Text',
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "28px",
    flex: '1 0 0',
    marginBottom: '32px'
  };

  const creditSMS_Text = {
    color: "var(--colorTextDescription, rgba(0, 0, 0, 0.45))",
    fontFamily: 'SF Pro Text',
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "22px"
  };

  const SMS_Counts = {
    color: "var(--colorText, rgba(0, 0, 0, 0.88))",
    fontFamily: 'SF Pro Text',
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "32px"
  };

  return (
    <div style={borderStyle}>
      <div style={{ padding: '32px' }}>
        <div style={subscriptionHistoryLayout}>
          <div style={{ flex: 1, maxWidth: '629px', maxHeight: '587px' }}>
            <div style={headingStyle}>SMS</div>
            <div style={borderStyle_Card}>
              <div style={{ maxWidth: '366px', height: '77px', padding: '32px' }}>
                <div style={{ display: 'flex', gap: '32px' }}>
                  <MessageFilled style={{ fontSize: '32px', color: '#1677ff' }} />
                  <div>
                    <div style={creditSMS_Text}>Credit SMS</div>
                    <div style={SMS_Counts}>{SMSCount}</div>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ paddingTop: '32px' }}>
              <Button type="primary" style={{ width: '236px', height: '32px' }} onClick={showModal}>
                + Add Credit SMS
              </Button>

              <Modal
                title="Add SMS Amount"
                open={isModalVisible}
                onCancel={closeModal}
                footer={null}
              >
                <Space>
                  <Button shape='circle' style={{ height: '80px', width: '80px' }} onClick={() => addSMS(10)}>10</Button>
                  <Button shape='circle' style={{ height: '80px', width: '80px' }} onClick={() => addSMS(30)}>30</Button>
                  <Button shape='circle' style={{ height: '80px', width: '80px' }} onClick={() => addSMS(100)}>100</Button>
                  <Button shape='circle' style={{ height: '80px', width: '80px' }} onClick={() => removeSMS(30)}>- 30</Button>
                  <Button shape='circle' style={{ height: '80px', width: '80px' }} onClick={() => removeSMS(10)}>-10</Button>
                </Space>
              </Modal>
            </div>
          </div>

          <div style={{ flex: 1, maxWidth: '629px', maxHeight: '587px' }}>
            <div style={{ maxWidth: '629px', height: '517px' }}>
              <div style={headingStyle}>Order History</div>
              <Table dataSource={orderHistoryData} columns={columns} pagination={{ pageSize: 5 }} />
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button style={{ margin: '0 8px' }}>Cancel</Button>
              <Button type="primary" onClick={handleAddSMSCredit}>Save changes</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormSMS;

import { publicCommonClient, commonClient } from '../clients';

const getTable = (tableName: string): Promise<any> => {
  return commonClient.post(`/api/adminPanel/table/${tableName}/getTable`);
};

const setInvoiceCredit = (data: any): Promise<any> => {
  return commonClient.post(`/api/adminPanel/invoiceCredit`, { data });
};

const setSMSCredit = (data: any): Promise<any> => {
  return commonClient.post(`/api/adminPanel/smsCredit`, { data });
};

const generateFormattedStripeSubList = (stripeCustomerId: string): Promise<any> => {
  return commonClient.post(`/api/adminPanel/generateFormattedStripeSubList`, {
    stripeCustomerId,
    mainSub: true,
    invoiceSub: true,
    smsSub: true,
  });
};

const updateTableEntry = (tableName: string, id: any, data: any): Promise<any> => {
  return commonClient.post(`/api/adminPanel/table/${tableName}/id/${id}/updateTable`, { data });
};

const updateTrialEndDate = (subscriptionId: string, date: any): Promise<any> => {
  return commonClient.post(`/api/adminPanel/trialEnd`, {
    subscriptionId,
    date,
  });
};

export default {
  getTable,
  setInvoiceCredit,
  setSMSCredit,
  generateFormattedStripeSubList,
  updateTableEntry,
  updateTrialEndDate,
};

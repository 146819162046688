import React, { useEffect } from 'react';
import { Form, Input, Button, Select, Radio } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { fetchUsers } from '../features/providers/userSlice';
import { fetchProviders, createAndInviteUser, resetPassword } from '../features/providers/providersSlice';
import { useProviderContext } from '../components/ProviderContext';
import { RadioChangeEvent } from 'antd/lib/radio';

const { Option } = Select;

interface FormValues {
  firstName?: string;
  lastName?: string;
  email: string;
  phone: string;
  gln?: string;
  zsr?: string;
  centreID?: string;
  countryPhone?: string;
  newPassword?: string;
  repeatPassword?: string;
}

interface UserFileProps {
  userId: string | null;
}

const UserFile: React.FC<UserFileProps> = ({ userId }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();

  const { selectedProviderId } = useProviderContext();

  const users = useSelector((state: RootState) => state.users.data);
  const usersStatus = useSelector((state: RootState) => state.users.status);

  const provider = useSelector((state: RootState) =>
    state.providers.data.find((prov) => prov.centre_id === selectedProviderId)
  );
  const providersStatus = useSelector((state: RootState) => state.providers.status);

  const [userType, setUserType] = React.useState<'provider' | 'consumer' | 'both'>('consumer');
  const [userStatus, setUserStatus] = React.useState<'Active' | 'Passive'>('Passive');

  useEffect(() => {
    if (usersStatus === 'idle') {
      dispatch(fetchUsers());
    }
    if (providersStatus === 'idle') {
      dispatch(fetchProviders());
    }
  }, [dispatch, usersStatus, providersStatus]);

  useEffect(() => {
    if (userId && usersStatus === 'succeeded') {
      const selectedUser = users.find((user) => user.user_id === userId);
      if (selectedUser) {
        form.setFieldsValue({
          firstName: selectedUser.firstName,
          lastName: selectedUser.lastName,
          email: selectedUser.email,
          phone: selectedUser.phone,
          gln: provider?.BusinessNumber?.[4]?.value,
          zsr: provider?.BusinessNumber?.[2]?.value,
          checkUserStatus: selectedUser.active ? 'Active' : 'Passive',
          checkTypeOfUser: provider ? 'provider' : 'consumer',
        });
        setUserStatus(selectedUser.active ? 'Active' : 'Passive');
        setUserType(provider ? 'provider' : 'consumer');
      }
    }
  }, [userId, users, usersStatus, form]);
  
  const onChangeRadioButton = (e: RadioChangeEvent) => {
    setUserType(e.target.value);
  };

  const onFinish = async (values: FormValues) => {
    console.log('Received values of form: ', values);

    if (values.newPassword && values.repeatPassword) {
      if (values.newPassword !== values.repeatPassword) {
        form.setFields([
          {
            name: 'repeatPassword',
            errors: ['The two passwords do not match.'],
          },
        ]);
        return;
      } else {
        await dispatch(resetPassword({ password: values.newPassword }));
        console.log('Password reset:', values.newPassword);
      }
    }

    if (values.centreID) {
      await dispatch(createAndInviteUser({
        centreId: values.centreID,
        invitationData: {
          User: { email: values.email, phone: values.phone.toString(), countryPhone: '+41' },
          senderId: '',
        }
      }));
      console.log('Invitation sent:', values);
    }
  };

  return (
    <>
      <div style={borderStyle}>
        <div style={{ padding: '32px' }}>
          <div style={styles_EditContact}>Edit Contact</div>
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            style={{ marginBottom: 'auto' }}
          >
            <div style={{ display: 'flex', gap: '32px', width: '100%' }}>
              <Form.Item
                name="firstName"
                label="First Name"
                style={{ flex: 1 }}
                rules={[{ message: 'Please input the first name!' }]}
              >
                <Input placeholder="Name" />
              </Form.Item>

              <Form.Item
                name="lastName"
                label="Last Name"
                style={{ flex: 1 }}
                rules={[{ message: 'Please input the last name!' }]}
              >
                <Input placeholder="lastName" />
              </Form.Item>
            </div>

            <div style={{ display: 'flex', gap: '32px', width: '100%' }}>
              <Form.Item
                name="gln"
                label="GLN"
                style={{ flex: 1 }}
              >
                <Input placeholder="GLN" />
              </Form.Item>

              <Form.Item
                name="zsr"
                label="ZSR"
                style={{ flex: 1 }}
              >
                <Input placeholder="ZSR" />
              </Form.Item>
            </div>

            <div style={{ display: 'flex', gap: '32px', width: '100%' }}>
              <Form.Item
                name="email"
                label="E-mail"
                style={{ flex: 1 }}
                rules={[
                  { type: 'email', message: 'The input is not valid E-mail!' },
                ]}
              >
                <Input placeholder="email@email.com" />
              </Form.Item>

              <Form.Item
                name="phone"
                label="Phone"
                style={{ flex: 1 }}
              >
                <Input
                  addonBefore={<Select defaultValue="+XX">
                    <Option value="+XX">+XX</Option>
                  </Select>}
                  placeholder="Phone Number"
                />
              </Form.Item>
            </div>

            <div style={{ display: 'flex', gap: '32px', width: '100%' }}>
              <Form.Item
                name="checkTypeOfUser"
                label="Type of User"
                style={{ flex: 1 }}
              >
                <Radio.Group onChange={onChangeRadioButton} value={userType} style={{ display: 'flex' }} defaultValue={"consumer"}>
                  <Radio value="provider">Provider</Radio>
                  <div style={{ width: '21px' }} />
                  <Radio value="consumer">Consumer</Radio>
                  <div style={{ width: '21px' }} />
                  <Radio value="both">Both</Radio>
                </Radio.Group>

              </Form.Item>

              <Form.Item
                name="checkUserStatus"
                label="User Status"
                style={{ flex: 1 }}
              >
                <Radio.Group value={userStatus} style={{ display: 'flex' }}>
                  <Radio value="Active">Active</Radio>
                  <div style={{ width: '21px' }} />
                  <Radio value="Passive">Passive</Radio>
                </Radio.Group>
              </Form.Item>
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div>
                <Button type="primary" onClick={() => form.submit()}>
                  Save changes
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>

      <div style={borderStyle_02}>
        <div style={{ padding: '32px' }}>
          <div style={styles_EditContact}>Clinics</div>
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            style={{ marginBottom: 'auto' }}
          >
            <div style={{ display: 'flex', gap: '32px', width: '100%' }}>
              <div>
                <p>{provider?.name}</p>
                <p>{provider?.Address}</p>
                <p>{provider?.email}</p>
                <p>{provider?.phone}</p>
              </div>
            </div>

            <div style={{ display: 'flex', gap: '32px', width: '100%' }}>
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div>
                <Button type="primary">
                  Save changes
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>

      <div style={borderStyle_03}>
        <div style={{ padding: '32px' }}>
          <div style={styles_EditContact}>Send Invitation</div>
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            style={{ marginBottom: 'auto' }}
          >
            <div style={{ maxHeight: '83px', maxWidth: '482px' }}>
              <Form.Item
                name="centreID"
                label="Centre ID"
                style={{ flex: 1 }}
              >
                <Input placeholder="Centre ID" />
              </Form.Item>
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div>
                <Button type="primary" onClick={() => form.submit()}>
                  Send Invitation
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>

      <div style={borderStyle_03}>
        <div style={{ padding: '32px' }}>
          <div style={styles_EditContact}>Reset Password</div>
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            style={{ marginBottom: 'auto' }}
          >
            <div style={{ display: 'flex', gap: '32px', width: '100%' }}>
              <Form.Item
                name="newPassword"
                label="New Password"
                style={{ flex: 1 }}
                rules={[
                  { required: true, message: 'Please input the new password!' },
                ]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>

              <Form.Item
                name="repeatPassword"
                label="Repeat Password"
                style={{ flex: 1 }}
                rules={[
                  { required: true, message: 'Please repeat the password!' },
                ]}
              >
                <Input.Password placeholder="Repeat Password" />
              </Form.Item>
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div>
                <Button type="primary" onClick={() => form.submit()}>
                  Reset Password
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>

      <div style={borderStyle_03}>
        <div style={{ padding: '32px' }}>
          <div style={styles_EditContact}>Delete Account</div>
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            style={{ marginBottom: 'auto' }}
          >
            <div style={{ display: 'flex', gap: '32px', width: '100%' }}>
              <Form.Item
                name="inputDel"
                label="Please enter DELETE if you want to delete this account."
                style={{ flex: 1 }}
              >
                <div style={{ maxHeight: '83px', maxWidth: '482px' }}>
                  <Input placeholder="DELETE" />
                </div>
              </Form.Item>
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div>
                <Button type="primary" onClick={() => form.submit()}>
                  Delete Account
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default UserFile;

const borderStyle = {
  borderRadius: "6px",
  border: "1px solid rgba(0, 0, 0, 0.06)",
  maxWidth: '1386px',
  maxHeight: '648px'
};

const borderStyle_02 = {
  borderRadius: "6px",
  border: "1px solid rgba(0, 0, 0, 0.06)",
  maxWidth: '1386px',
  maxHeight: '648px',
  marginTop: '32px'
};

const borderStyle_03 = {
  borderRadius: "6px",
  border: "1px solid rgba(0, 0, 0, 0.06)",
  maxWidth: '1386px',
  maxHeight: '296px',
  marginTop: '32px'
};

const styles_EditContact = {
  color: 'var(--colorTextHeading, rgba(0, 0, 0, 0.88))',
  fontFamily: 'SF Pro Text',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '32px',
  paddingBottom: '32px'
};

import React, { useState } from 'react';
import { Tabs } from 'antd';
import UserFile from '../forms/UserFile';
import UserList from '../forms/UserList';

const { TabPane } = Tabs;

const HeaderBarAccounts02: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>('1');
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);

  const callback = (key: string) => {
    setActiveTab(key);
  };

  const handleUserSelect = (userId: string) => {
    setSelectedUserId(userId);
    setActiveTab('2'); // Switch to the User tab
  };

  const renderUserList = () => <UserList onUserSelect={handleUserSelect} />;
  const renderFormUser = () => <UserFile userId={selectedUserId} />;

  return (
    <div className="div" style={{ marginLeft: '10px' }}>
      <Tabs activeKey={activeTab} onChange={callback}>
        <TabPane tab="UserList" key="1">
          {activeTab === '1' && renderUserList()}
        </TabPane>
        <TabPane tab="User" key="2">
          {activeTab === '2' && renderFormUser()}
        </TabPane>
      </Tabs>
    </div>
  );
};

export default HeaderBarAccounts02;

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

interface TwoFactorAuthState {
  otpToken: string | null;
  isLoading: boolean;
  error: string | null;
  success: boolean;
}

interface GenerateOTPTokenResponse {
  token: string;
}

interface GenerateOTPTokenError {
  message: string;
}

const commonServiceApiUrl = process.env.REACT_APP_COMMON_SERVICE_API_URL;

export const generateOTPToken = createAsyncThunk<GenerateOTPTokenResponse, string, { rejectValue: GenerateOTPTokenError }>(
    'twoFactorAuth/generateOTPToken',
    async (secretKey, { rejectWithValue }) => {
        try {
            const response = await axios.post<GenerateOTPTokenResponse>(`${commonServiceApiUrl}/api/adminPanel/generateOTPToken`, {
                secretKey
            });
            return response.data;
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                return rejectWithValue({ message: error.response.data.message });
            }
            return rejectWithValue({ message: 'Failed to generate OTP token' });
        }
    }
);

const initialState: TwoFactorAuthState = {
    otpToken: null,
    isLoading: false,
    error: null,
    success: false
};

const twoFactorAuthSlice = createSlice({
    name: 'twoFactorAuth',
    initialState,
    reducers: {
        resetTwoFactorAuthState: (state) => {
            state.isLoading = false;
            state.error = null;
            state.success = false;
            state.otpToken = null;
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(generateOTPToken.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(generateOTPToken.fulfilled, (state, action) => {
            state.isLoading = false;
            state.success = true;
            state.otpToken = action.payload.token;
        })
        .addCase(generateOTPToken.rejected, (state, action) => {
            state.isLoading = false;
            //state.error = action.payload?.message;
        });
    }
});

export const { resetTwoFactorAuthState } = twoFactorAuthSlice.actions;
export default twoFactorAuthSlice.reducer;

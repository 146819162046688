import React, { useState, useEffect } from 'react';
import { Menu, Input, Table, Tag, Pagination, Dropdown, Button, Form, Modal } from 'antd';
import { SearchOutlined, MoreOutlined, SaveOutlined, EditOutlined, CloseOutlined } from '@ant-design/icons';
import 'antd/dist/reset.css';
import Search from 'antd/es/input/Search';
import { AppDispatch, RootState } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProviders, updateTableEntry } from '../features/providers/providersSlice';
import { useProviderContext } from '../components/ProviderContext';

const { confirm } = Modal;

interface User {
    id: string;
    name: string;
    email: string;
    gln: string;
    status: string;
}

interface EditableCellProps {
    editing: boolean;
    dataIndex: string;
    title: string;
    inputType?: 'text' | 'email';
    record: User;
    index: number;
    children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType = 'text',
    record,
    index,
    children,
    ...restProps
}) => {
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[{ required: true, message: `Please Input ${title}!` }]}
                >
                    <Input />
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const FormUsers: React.FC = () => {
    const [form] = Form.useForm();
    const [users, setUsers] = useState<User[]>([]);
    const [editingKey, setEditingKey] = useState<string>('');
    const [searchText, setSearchText] = useState<string>('');
    const [currentPage, setCurrentPage] = useState<number>(1);
    const pageSize = 4;

    const dispatch = useDispatch<AppDispatch>();
    const { selectedProviderId } = useProviderContext();

    const providers = useSelector((state: RootState) =>
        state.providers.data.find(prov => prov.centre_id === selectedProviderId)
    );

    const providersStatus = useSelector((state: RootState) => state.providers.status);

    useEffect(() => {
        if (providersStatus === 'idle') {
            dispatch(fetchProviders());
        }
    }, [dispatch, providersStatus]);

    useEffect(() => {
        if (providersStatus === 'succeeded' && providers) {
            const mappedUsers = [{
                id: providers?.User.user_id,
                name: `${providers.User?.firstName} ${providers.User?.lastName}`,
                email: providers?.User?.email,
                gln: providers?.BusinessNumber[4]?.value,
                status: providers?.User.active,
            }];
            setUsers(mappedUsers);
        }
    }, [providers, providersStatus]);

    useEffect(() => {
        setCurrentPage(1);
    }, [searchText]);

    const isEditing = (record: User) => record.id === editingKey;

    const edit = (record: User) => {
        form.setFieldsValue({
            ...record,
            name: record.name,
            email: record.email,
            gln: record.gln,
        });
        setEditingKey(record.id);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const save = async (id: string) => {
        confirm({
            title: 'Are you sure you want to save these changes?',
            async onOk() {
                try {
                    const row = await form.validateFields();
                    const newData = [...users];
                    const index = newData.findIndex((item) => id === item.id);

                    if (index > -1) {
                        const item = newData[index];
                        newData.splice(index, 1, { ...item, ...row });
                        setUsers(newData);
                        setEditingKey('');
                        const userId = providers?.User?.user_id;
                        await dispatch(updateTableEntry({
                            tableName: 'User',
                            id: userId,
                            data: {
                                firstName: row.name.split(' ')[0],
                                lastName: row.name.split(' ')[1],
                                email: row.email,
                                
                            },
                        }));
                        dispatch(fetchProviders());
                    } else {
                        newData.push(row);
                        setUsers(newData);
                        setEditingKey('');
                    }
                } catch (errInfo) {
                    console.log('Validate Failed:', errInfo);
                }
            },
        });
    };

    const handleDelete = (id: string) => {
        confirm({
            title: 'Are you sure you want to delete this user?',
            onOk() {
                const filteredUsers = users.filter((user) => user.id !== id);
                setUsers(filteredUsers);
            },
        });
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    };

    const filteredUsers = users.filter(user =>
        user.name.toLowerCase().includes(searchText.toLowerCase()) ||
        user.email.toLowerCase().includes(searchText.toLowerCase()) ||
        user.gln.toLowerCase().includes(searchText.toLowerCase())
    );

    const columns = [
        { title: 'ID', dataIndex: 'id', width: '10%' },
        { title: 'Name', dataIndex: 'name', editable: true },
        { title: 'Email', dataIndex: 'email', editable: true },
        { title: 'GLN', dataIndex: 'gln', editable: true },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (status: boolean) => (
                <Tag color={status ? 'blue' : 'orange'}>{status ? 'Active' : 'Inactive'}</Tag>
            )
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (_: any, record: User) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <Button onClick={() => save(record.id)} style={{ marginRight: 8 }} icon={<SaveOutlined />} />
                        <Button onClick={cancel} icon={<CloseOutlined />} />
                    </span>
                ) : (
                    <Dropdown
                        overlay={
                            <Menu>
                                <Menu.Item key="0" disabled={editingKey !== ''} onClick={() => edit(record)} icon={<EditOutlined />}>
                                    Edit
                                </Menu.Item>
                                <Menu.Item key="1" onClick={() => handleDelete(record.id)} icon={<CloseOutlined />}>
                                    Delete
                                </Menu.Item>
                            </Menu>
                        }
                        trigger={['click']}
                    >
                        <Button shape="circle" icon={<MoreOutlined />} />
                    </Dropdown>
                );
            },
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record: User) => ({
                record,
                inputType: col.dataIndex === 'email' ? 'email' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    return (
        <div style={borderStyle_Card}>
            <div style={{ maxWidth: '1323px', height: '643px', padding: '32px' }}>
                <div style={headingStyle}>Users</div>
                <div style={{ maxWidth: '1323px', height: '43px', marginBottom: '32px' }}>
                    <Search
                        placeholder="Search by name, email, or GLN"
                        onChange={handleSearch}
                        enterButton
                    />
                </div>
                <div style={{ maxWidth: '1323px', height: '499px', marginBottom: '32px' }}>
                    <Form form={form} component={false}>
                        <Table
                            components={{
                                body: {
                                    cell: EditableCell,
                                },
                            }}
                            columns={mergedColumns}
                            dataSource={filteredUsers}
                            pagination={false}
                            rowClassName="editable-row"
                            rowKey="id"
                        />
                        <div style={{ textAlign: 'right', maxWidth: '1323px', height: '43px', paddingTop: '21px' }}>
                            <Pagination
                                current={currentPage}
                                onChange={handlePageChange}
                                pageSize={pageSize}
                                total={filteredUsers.length}
                            />
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default FormUsers;

const borderStyle_Card = {
    borderRadius: "8px",
    border: "1px solid rgba(0, 0, 0, 0.06)",
    maxWidth: '1387px', height: '707px',
};

const headingStyle = {
    color: "var(--colorTextHeading, rgba(0, 0, 0, 0.88))",
    fontFamily: 'SF Pro Text',
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "28px",
    flex: '1 0 0',
    marginBottom: '32px'
};

// ProviderContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';

interface User {
  email: string;
  name: string;
}

interface ProviderContextType {
  selectedProviderId: string | null;
  setSelectedProviderId: (id: string | null) => void;
  selectedUserId: string | null;
  setSelectedUserId: (id: string | null) => void;
  user: User | null;
  setUser: (user: User | null) => void;
}

const ProviderContext = createContext<ProviderContextType | undefined>(undefined);

interface ProviderProps {
  children: ReactNode;
}

export const ProviderContextProvider: React.FC<ProviderProps> = ({ children }) => {
  const [selectedProviderId, setSelectedProviderId] = useState<string | null>(null);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [user, setUser] = useState<User | null>(null);

  return (
    <ProviderContext.Provider value={{ selectedProviderId, setSelectedProviderId, selectedUserId, setSelectedUserId, user, setUser }}>
      {children}
    </ProviderContext.Provider>
  );
};

export const useProviderContext = () => {
  const context = useContext(ProviderContext);
  if (context === undefined) {
    throw new Error('useProviderContext must be used within a ProviderContextProvider');
  }
  return context;
};

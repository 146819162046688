import React from 'react';
import { Table, Tag, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { fetchUsers } from '../features/providers/userSlice';

interface UserListProps {
  onUserSelect: (userId: string) => void;
}

const UserList: React.FC<UserListProps> = ({ onUserSelect }) => {
  const [searchText, setSearchText] = React.useState<string>('');
  const dispatch = useDispatch<AppDispatch>();

  const users = useSelector((state: RootState) => state.users.data);

  React.useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  const mappedData = users.map(user => ({
    id: user?.user_id,
    key: user?.user_id,
    name: `${user?.firstName} ${user?.lastName}`,
    email: user?.email,
    clinics: '1',
    gln: '18312891',
    status: user?.active,
  }));

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const filteredData = mappedData.filter(user =>
    user.name.toLowerCase().includes(searchText.toLowerCase()) ||
    user.email.toLowerCase().includes(searchText.toLowerCase()) ||
    user.gln.toLowerCase().includes(searchText.toLowerCase())
  );

  const onRowClick = (record: { id: string }) => {
    onUserSelect(record.id);
  };

  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    { title: 'Clinics', dataIndex: 'clinics', key: 'clinics' },
    { title: 'GLN', dataIndex: 'gln', key: 'gln' },
    { 
      title: 'Status', 
      key: 'status', 
      dataIndex: 'status', 
      render: (status: boolean) => (
        <Tag color={status ? 'blue' : 'orange'}>{status ? 'Active' : 'Inactive'}</Tag>
      )
    },
  ];

  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <Input placeholder="Search by name, email, or GLN" onChange={handleSearch} />
      </div>
      <Table 
        columns={columns} 
        dataSource={filteredData} 
        pagination={{ position: ['bottomRight'] }} 
        onRow={(record) => ({
          onClick: () => onRowClick(record),
        })}
      />
    </div>
  );
};

export default UserList;
